/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import Finances from '../../../widgets/Finances';
import Carousel from '../../../shared/Carousel';
import posterImg from '../../../../_metronic/assets/media/17-dark.png';
import {Button} from '../../../shared/Buttons';
import SalesLtcTeam from './SalesLtsTeam';
import SalesRdTeam from './SalesRdTeam';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import {Link} from 'react-router-dom';
import {useThemeMode} from '../../../../_metronic/partials';
import {formatNumberToCash, formatNumber, formatNumber2} from '../../../functions/formatNumber';
import {SelectorWithSearch} from '../../../shared/Selectors';
import BannerTopManager from './BannerTopManager';
import Activity from './Activity';
import AI from './AI';

const DashboardPage = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [mode, setMode] = useState('');

  const [salesStats, setSalesStats] = useState({});

  const [ltcList, setLtcList] = useState([]);
  const [ltcTeams, setLtcTeams] = useState({});

  const [rdList, setRdList] = useState([]);
  const [rdTeams, setRdTeams] = useState({});

  const [ltcStats, setLtcStats] = useState();
  const [rdStats, setRdStats] = useState();

  const [advertisers, setAdvertisers] = useState([]);
  const [sources, setSources] = useState([
    {value: 'facebook', label: 'Фейсбук'},
    {value: 'influence', label: 'Инфлюенс'},
  ]);
  const [workspaces, setWorkspaces] = useState([]);
  const [bots, setBots] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [channels, setChannels] = useState([]);

  const [advertiser, setAdvertiser] = useState({});
  const [source, setSource] = useState({});
  const [workspace, setWorkspace] = useState({});
  const [bot, setBot] = useState({});
  const [account, setAccount] = useState({});
  const [channel, setChannel] = useState({});

  const [params, setParams] = useState({});

  const [managersTop, setManagersTop] = useState({});

  const [dataAi, setDataAi] = useState({});

  useEffect(() => {
    let queryStr = '';
    for (let [key, value] of Object.entries(params)) {
      queryStr += `${key}=${value}`;
    }
    isVerify && timeConfig.time_config && getData(queryStr);
    isVerify && timeConfig.time_config && getDataAI();
  }, [isVerify, timeConfig]);

  useEffect(() => {
    isVerify && getFilters();
  }, [isVerify]);

  const createParams = async (field, value) => {
    const newParams = {...params, [field]: value};
    setParams(newParams);
    let queryStr = '';
    for (let [key, value] of Object.entries(newParams)) {
      queryStr += `${key}=${value}`;
    }

    getData(queryStr);
  };

  const deleteParams = async (field) => {
    const newParams = {...params};
    delete newParams[field];
    setParams(newParams);
    let queryStr = '';
    for (let [key, value] of Object.entries(newParams)) {
      queryStr += `${key}=${value}`;
    }

    getData(queryStr);
  };

  const getFilters = async () => {
    const [getAdvertisers, getWorkspaces, getBots, getAccounts, getChannels] = await Promise.all([
      store.getAdvertisersList(),
      store.CRMWorkspaces('sales'),
      store.botsGetListBots(),
      store.tgAccountsGetTgAccounts(),
      store.channelsGetChannelsList(''),
    ]);

    setAdvertisers(
      getAdvertisers.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setWorkspaces(
      getWorkspaces.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setBots(
      getBots.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setAccounts(
      getAccounts.map((item) => {
        return {value: item.id, label: `${item.username} #${item.id}`};
      })
    );
    setChannels(
      getChannels.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setPreloader(false);
  };

  const getData = async (filter = '') => {
    const [getSalesStats, ltcList, rdList, getLtcStats, getRdStats, getManagersTop, LtcTeams, RdTeams] =
      await Promise.all([
        store.getSalesStats(timeConfig, filter),
        store.financeCategoryGetLtcList(),
        store.financeCategoryGetRdList(),
        store.salesGetLtcStats(timeConfig, filter),
        store.salesGetRdStats(timeConfig, filter),
        store.salesGetManagersTop(timeConfig),
        store.salesTeamsLtcGetTeams(timeConfig, filter),
        store.salesTeamsRdGetTeams(timeConfig, filter),
      ]);

    const ltc = ltcList.find(
      (el) => +getLtcStats.ltc >= +el.ltc_min && +getLtcStats.ltc <= +el.ltc_max
    );

    const rd = rdList.find((el) => +getRdStats.rd >= +el.rd_min && +getRdStats.rd <= +el.rd_max);
    setManagersTop(getManagersTop);
    setSalesStats(getSalesStats);

    setLtcList(ltcList);
    setRdList(rdList);

    setLtcStats({...getLtcStats, indicator: ltc});
    setRdStats({...getRdStats, indicator: !rd ? {indicator: 'success'} : rd});
    setLtcTeams(LtcTeams.data);
    setRdTeams(RdTeams.data);
  };

  const getDataAI = async () => {
    const result = await store.salesGetAiStats(timeConfig);

    setDataAi(result);
  };

  const getTeams = async (filter = '') => {
    const getLtcTeams = await store.salesTeamsLtcGetTeams(timeConfig, filter);
    const getRdTeams = await store.salesTeamsRdGetTeams(timeConfig, filter);

    setLtcTeams(getLtcTeams.data);
    setRdTeams(getRdTeams.data);
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <>
      <div className='filtersSalesPanel g-5 g-xl-10 my-5'>
        <label
          htmlFor='filtersSalesPanel'
          className='filtersSalesPanel_activator fw-bold text-gray-800'
          style={{
            backgroundColor: `${mode === 'dark' ? '#15171C' : '#ffffff'}`,
          }}
        >
          Фильтра
          <input className='' type='checkbox' name='' id='filtersSalesPanel' />
          <i className='ki-duotone ki-down fs-1'></i>
          <i className='ki-duotone ki-up fs-1'></i>
        </label>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Рекламодатель...'
            options={advertisers}
            actions={(e) => {
              setAdvertiser(e);
              if (e === null) {
                return deleteParams('&advertiser_id');
              }
              createParams('&advertiser_id', e.value);
            }}
            value={advertiser}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Источник...'
            options={sources}
            actions={(e) => {
              setSource(e);
              if (e === null) {
                return deleteParams('&source');
              }
              createParams('&source', e.value);
            }}
            value={source}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Workspaces...'
            options={workspaces}
            actions={(e) => {
              setWorkspace(e);
              if (e === null) {
                return deleteParams('&workspace_id');
              }
              createParams('&workspace_id', e.value);
            }}
            value={workspace}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Боты...'
            options={bots}
            actions={(e) => {
              setBot(e);
              if (e === null) {
                return deleteParams('&bot_id');
              }
              createParams('&bot_id', e.value);
            }}
            value={bot}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Аккаунты...'
            options={accounts}
            actions={(e) => {
              setAccount(e);
              if (e === null) {
                return deleteParams('&tg_account_id');
              }
              createParams('&tg_account_id', e.value);
            }}
            value={account}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Каналы...'
            options={channels}
            actions={(e) => {
              setChannel(e);
              if (e === null) {
                return deleteParams('&channel_id');
              }
              createParams('&channel_id', e.value);
            }}
            value={channel}
            clearable={true}
          />
        </div>
      </div>
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-4 mb-xl-10 mt-0'>
          <Finances
            background={'op'}
            title='Статистика'
            subTitle='Управляйте периодом в календаре.'
            stats={[
              {
                title: 'Пользователей',
                number: formatNumber(salesStats?.leads?.count) || 0,
                icon: (
                  <i className='ki-duotone ki-fingerprint-scanning fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                  </i>
                ),
              },
              {
                title: 'Регистрации',
                number: formatNumber(salesStats?.registration?.count) || 0,
                icon: (
                  <i className='ki-duotone ki-user-edit fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
              {
                title: 'Обращения',
                number: formatNumber(salesStats?.appeals?.count) || 0,
                icon: (
                  <i className='ki-duotone ki-wallet fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                  </i>
                ),
              },
              {
                title: 'Квалификации',
                number: formatNumber(salesStats?.qualifications?.count) || 0,
                icon: (
                  <i className='ki-duotone ki-user-tick fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
            ]}
          />
        </div>

        <div className='col-xl-8 mb-5 mb-xl-10 mt-xl-0'>
          <div className='row g-5 g-xl-10'>
            <div className='col-xl-6 mb-xl-10'>
              <Carousel title={'LTC'} subTitle={'Tекущие KPI'}>
                <div className={`carousel-item`}>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='w-80px flex-shrink-0 me-2 symbol symbol-70px symbol-circle me-5'>
                      <span
                        className={`symbol-label bg-light-${ltcStats?.indicator?.indicator} w-70`}
                      >
                        <i
                          className={`ki-duotone ki-abstract-24 fs-3x text-${ltcStats?.indicator?.indicator}`}
                        >
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    </div>

                    <div className='m-0'>
                      <h4 className='fw-bold text-gray-800 mb-3'>
                        {ltcStats?.ltc}%
                        <span
                          className={`badge badge-light-${ltcStats?.indicator?.indicator} fs-5 fw-bold`}
                        >
                          {ltcStats?.indicator?.name}
                        </span>
                      </h4>
                      <div className='d-flex d-grid gap-5'>
                        <div className='d-flex flex-column flex-shrink-0 me-4'>
                          <span className='d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Обращений: {formatNumber(ltcStats?.count_appeal)}
                          </span>

                          <span className='d-flex align-items-center text-gray-400 fw-bold fs-7'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Квалификаций: {formatNumber(ltcStats?.count_qualifications)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={`/sales/ltc/kpi`}>
                    <Button color={'btn-primary'}>Подробнее</Button>
                  </Link>
                </div>
              </Carousel>
            </div>

            <div className='col-xl-6 mb-5 mb-xl-10'>
              <Carousel title={'RD'} subTitle={'Текущие KPI'}>
                <div className={`carousel-item active`}>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='w-80px flex-shrink-0 me-2 symbol symbol-70px symbol-circle me-5'>
                      <span
                        className={`symbol-label bg-light-${rdStats?.indicator?.indicator} w-70`}
                      >
                        <i
                          className={`ki-duotone ki-abstract-24 fs-3x text-${rdStats?.indicator?.indicator}`}
                        >
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    </div>

                    <div className='m-0'>
                      <h4 className='fw-bold text-gray-800 mb-3'>
                        {rdStats?.rd}$
                        <span
                          className={`badge badge-light-${rdStats?.indicator?.indicator} fs-5 fw-bold`}
                        >
                          {rdStats?.indicator?.indicator === 'danger' && 'Плохой результат'}
                          {rdStats?.indicator?.indicator === 'warning' && 'Средний результат'}
                          {rdStats?.indicator?.indicator === 'success' && 'Хороший результат'}
                        </span>
                      </h4>

                      <div className='d-flex d-grid gap-5'>
                        <div className='d-flex flex-column flex-shrink-0 me-4'>
                          <span className='d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Активность: {formatNumber2(rdStats?.activity)}%
                          </span>

                          <span className='d-flex align-items-center text-gray-400 fw-bold fs-7'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Сумма Депозитов: {formatNumberToCash(rdStats?.amounts_deposits)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Link to={`/sales/rd/kpi`}>
                    <Button color={'btn-primary'}>Подробнее</Button>
                  </Link>
                </div>
              </Carousel>
            </div>
          </div>

          <BannerTopManager managers={managersTop} />
        </div>
        <div className='col-xl-12 mb-5 mb-xl-10 mt-0'>
          <h3 className='fw-bold fs-1 mb-5'>AI Статистика</h3>
          <AI data={dataAi}/>
        </div>
        <div className='col-xl-12 mb-5 mb-xl-10 mt-0'>
          <SalesLtcTeam
            title={'Команды LTC'}
            subTitle={`Кол-во менеджеров: `}
            headers={['Команда', 'Сотрудники', 'Обращения', 'Квалификации', 'LTC']}
            arrayList={ltcList}
            teams={ltcTeams}
            getTeams={getTeams}
          />
        </div>
        <div className='col-xl-12 mb-10 mt-0'>
          <SalesRdTeam
            title={'Команды RD'}
            subTitle={`Кол-во менеджеров: `}
            headers={['Команда', 'Сотрудники', 'Лиды', 'RD', 'Активность']}
            arrayList={rdList}
            teams={rdTeams}
            getTeams={getTeams}
          />
        </div>
        <div className='col-xl-12 mb-10 mt-0'>
          <Activity isVerify={isVerify} />
        </div>
      </div>
    </>
  );
});

const SalesPanel = () => {
  return (
    <PageWrapper
      firstTimeConfig='week_salles'
      mode='sales'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Панель', path: '/dashboard/sales', isActive: true},
      ]}
      title={'Панель отдела продаж'}
    >
      <DashboardPage />
    </PageWrapper>
  );
};

export default SalesPanel;
